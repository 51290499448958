import React, { useState } from 'react'
import styled from "styled-components";
import identificationIcon from "../../../images/icons/identification.svg"
import conectionIcon from "../../../images/icons/connection.svg"
import structureIcon from "../../../images/icons/structure.svg"
import accompanimentIcon from "../../../images/icons/accompaniment.svg"
import FadeInSection from '../../_components/FadeInSection';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const ServicesInfoCard = ({ texts, service, i, implementationActions = [] }) => {
  const [openCard, setOpenCard] = useState(false)

  const [currentIndexImplementationCard, setCurrentIndexImplementationCard] = useState(0)
  const currentImplementationAction = implementationActions[currentIndexImplementationCard]
  const isImplementationCard = i === 2

  const actionsImg = [identificationIcon, conectionIcon, structureIcon, accompanimentIcon]

  const selectImplementationAction = (index)=>{
    if (!openCard) setOpenCard(true)
    setCurrentIndexImplementationCard(index)
  }
  
  return (
    <ServicesCard>
      <FadeInSection>
        <ServicesCardCont $index={i}>
          <LazyLoadImage src={service.image} alt="icon" width="100%" height="100%" style={{maxWidth: "21.4rem", maxHeight: "21.4rem", borderRadius: "50%"}}/>
          <ServicesCardInfoCont>
            <ServicesCardInfo>
              <ServicesCardTitle>{service.title}</ServicesCardTitle>
              <ServicesCardDescription>{service.text}</ServicesCardDescription>
            </ServicesCardInfo>
            <ServicesCardInfo>
              <ServicesCardSubTitle>{texts.services_info_card_target}:</ServicesCardSubTitle>
              <ServicesCardDescription>{service.target}</ServicesCardDescription>
            </ServicesCardInfo>
            <ServicesCardButtonCont $index={i}>
              <ServicesCardShowAction $index={i} onClick={isImplementationCard ? ()=>{} : ()=>setOpenCard(!openCard)}>{texts.services_info_card_see_actions} {isImplementationCard ? ">": "+"}</ServicesCardShowAction>
              {isImplementationCard && implementationActions?.map((_, ii)=>
                <ServicesCardButtonContIcon $selected={currentIndexImplementationCard === ii && openCard} onClick={()=>selectImplementationAction(ii)} key={"button"+ii}>
                  <LazyLoadImage src={actionsImg[ii]} alt={"action"+ii} width="100%" height="100%" style={{maxWidth: "8rem", maxHeight: "8rem"}}/>
                </ServicesCardButtonContIcon>
              )}
            </ServicesCardButtonCont>
          </ServicesCardInfoCont>
        </ServicesCardCont>
      </FadeInSection>
      {isImplementationCard ? //implementation card
      <ServicesCardActions $openCard={openCard} $index={currentIndexImplementationCard} $implementationCard={i===2}>
        <ServicesCardActionsCont>
          <ServicesCardActionsInfo $implementationCard={isImplementationCard} $index={currentIndexImplementationCard}>
            <ServicesCardInfo>
              <ServicesCardImplementationTitle>
                {/* <LazyLoadImage src={actionsImg[currentIndexImplementationCard]} alt={"action"+currentIndexImplementationCard} width="8rem" height="8rem"/> */}
                <ServicesCardTitle $implementationCard={isImplementationCard}>{currentImplementationAction?.title}</ServicesCardTitle>
              </ServicesCardImplementationTitle>
              <ServicesCardDescription dangerouslySetInnerHTML={{__html: currentImplementationAction?.description}}/>
            </ServicesCardInfo>
              <ServicesCardActionsTitle>{texts.services_info_card_actions}:</ServicesCardActionsTitle>
              <ServicesCardActionsItemsCont>
                {currentImplementationAction?.actions?.map((action, i)=>
                  <li key={"action"+i}>{action}</li>
                )}
              </ServicesCardActionsItemsCont>
          </ServicesCardActionsInfo>
        </ServicesCardActionsCont>
      </ServicesCardActions>
      :
      <ServicesCardActions $openCard={openCard} $index={i}>
        <ServicesCardActionsCont>
          <ServicesCardActionsInfo>
            <ServicesCardActionsTitle>{texts.services_info_card_actions}</ServicesCardActionsTitle>
            <ServicesCardActionsItemsCont>
              {service?.actions?.map((action, i)=>
                <li key={"action"+i}>{action}</li>
              )}
            </ServicesCardActionsItemsCont>
          </ServicesCardActionsInfo>
        </ServicesCardActionsCont>
      </ServicesCardActions>}
    </ServicesCard>
  )
}

export default ServicesInfoCard

const rectangleColors = ["#ED245DCC", "#7E143BCC", "#351A3CCC", "#351A3CCC"]
const rectangleActionColors = ["#ED245D", "#7E143B", "#351A3C", "#351A3C"]
const implementationActionColors = ["#FCD009CC", "#EE6B34CC", "#0C3C08CC", "#28118CCC"]

const ServicesCard = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
`

const ServicesCardCont = styled.div`
  width: 100%;
  height: 54.9rem;
  display: flex;
  align-items: center;
  padding: 3% 10%;
  gap: 3rem;
  user-select: none;
  background-color: ${({$index}) => rectangleColors[$index%4]};

  @media (max-width: 500px) {
    max-height: max-content;
    height: auto;
    flex-direction: column;
    align-items: center;
    padding: 5rem 10%;
  } 
`

const ServicesCardInfoCont = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: auto;
  color: white;
  font-family: var(--Roboto);
`

const ServicesCardInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const ServicesCardImplementationTitle = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;
  margin: 0 0 3rem;
`

const ServicesCardTitle = styled.h4`
  font-family: var(--BarlowCondensed);
  font-size: 5rem;
  font-weight: 400;
  text-transform: uppercase;
  margin: ${({$implementationCard}) => $implementationCard ? "2rem 0" : "0 0 2rem"};
`

const ServicesCardSubTitle = styled.h5`
  font-size: 3rem;
  font-weight: 500;
  margin: 0;
  @media (max-width: 500px) {
    margin: 2rem 0 0;
  }
`

const ServicesCardDescription = styled.span`
  font-size: 3rem;
  font-weight: 300;
  display: flex;
  align-items: center;
  
  @media (max-width: 900px) {
    font-size: 2.5rem;
  }
`

const ServicesCardButtonCont = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;
  width: 100%;
  justify-content: ${({$index}) => $index === 2 ? "start" : "end" };
  padding: 1rem 0;
`

const ServicesCardButtonContIcon = styled.div`
  cursor: pointer;
  scale: ${({$selected}) => $selected ? "1.2" : "1" };
  transition: scale 0.3s ease;
`

const ServicesCardShowAction = styled.button`
  margin: 2rem 0 0;
  background-color: transparent;
  appearance: none;
  border: none;
  color: white;
  text-transform: uppercase;
  font-size: 3rem;
  font-weight: 300;
  cursor: ${({$index}) => $index === 2 ? "" : "pointer" };
`

const ServicesCardActions = styled.div`
  display: grid;
  grid-template-rows: ${({$openCard}) => $openCard ? "1fr" : "0fr"};
  transition: grid-template-rows 0.5s ease-out;
  background-color: ${({$implementationCard}) => $implementationCard ? ({$index}) => implementationActionColors[$index%4] : ({$index}) => rectangleActionColors[$index%4]} ;
`

const ServicesCardActionsCont = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const ServicesCardActionsInfo = styled.div`
  width: 100%;
  height: ${({$implementationCard}) => $implementationCard ? "86.4rem" : "54.9rem"};
  font-size: 3rem;
  padding: 5% 10%;
  user-select: none;
  color: ${({$implementationCard}) => $implementationCard ? ({$index}) => $index === 0 ? "#474747" : "white" : "white"} ;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 500px) {
    max-height: max-content;
    height: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5rem 10%;
  } 
`

const ServicesCardActionsTitle = styled.h5`
  font-weight: 500;
  margin: 0;
  font-size: 3rem;
  margin: 2rem 0;
`

const ServicesCardActionsItemsCont = styled.ol`
  width: 100%;
  display: flex;
  flex-direction: column;
  font-weight: 300;
  margin: 0;
`