import styled from "styled-components";

const ViewContain = styled.div`
  width: 100%;
  padding: ${({$paddingY}) => $paddingY || 0} ${({$paddingX}) => $paddingX || 0};
  display: flex;
  flex-direction: ${({$flexDirection}) => $flexDirection || "column"};
  align-items: ${({$alignItems}) => $alignItems || ""};
  justify-content: ${({$justifyContent}) => $justifyContent || ""};
  background-color: ${({$bgColor}) => $bgColor || ""};
  gap: ${({$gap}) => $gap || ""};
  counter-reset: section;
  flex-wrap: ${({$flexWrap}) => $flexWrap ? "wrap" : ""};
`

export default ViewContain