import styled from "styled-components";

const PurpleButton = styled.button`
  width: ${({$width}) => $width || "auto"};
  max-width: 100%;
  height: ${({$height}) => $height || "auto"};
  border-radius: 6px;
  border: none;
  background-color: var(--purple2);
  font-family: var(--Roboto);
  font-size: ${({$fontSize}) => $fontSize || "2.4rem"} ;
  font-weight: 400;
  color: white;
  white-space: nowrap;
  padding: ${({$paddingY}) => $paddingY || 0} ${({$paddingX}) => $paddingX || 0};
  cursor: pointer;
  transition: all .5s;
  filter: grayscale(${({$disable}) => $disable ? "1" : "0"});
  text-transform: ${({$textTransform}) => $textTransform || ""};
  text-wrap: auto;

  &:hover{
    color: white;
    background-color: #696863;
  }
  
  &:active{
    transform: scale(1.1);
  }

  @media (max-width: 440px) {
    width: ${({$width}) => $width || "auto"};
    height: auto;
    font-size: ${({$fontSize}) => $fontSize || "3rem"};
    padding: ${({$paddingY}) => $paddingY || "1rem"} ${({$paddingX}) => $paddingX || "1rem"};
  }
`

export default PurpleButton