import React from "react";
import SectionBridgeMap from "./_components/SectionBridgeMap";
import styled from "styled-components";
import ViewContain from "../_components/ViewContain";
import InfoTitle from "../_components/InfoTitle";
import BridgeInfo from "./_components/BridgeInfo";
import BridgeBanner from "./_components/BridgeBanner";

import bannerImage from "../../images/banners/Bridge2ZeroBanner.png"
import imginfo1 from "../../images/svg/information.svg"
import imginfo2 from "../../images/svg/evaluation.svg"
import imginfo3 from "../../images/svg/register.svg"
import imginfo4 from "../../images/svg/contact.svg"
import imginfo5 from "../../images/svg/ai.svg"
import PurpleButton from "../_components/PurpleButton";
import FadeInSection from "../_components/FadeInSection";

const BridgeIndex = ({ texts }) => {

  const contactButton = () => {
    let sectionContact = document.getElementById("contact");
    sectionContact.scrollIntoView({ behavior: "smooth" });
  };
  
  const bannerData = {
    banner_image: bannerImage,
    banner_title: "Bridge<span>2</span>Zero",
    banner_text1: texts.bridge_banner_info_1,
    banner_text2: texts.bridge_banner_info_2,
    text_button: texts.bridge_text_button,
    button_action: contactButton,
  }

  return (
    <>
      <BridgeBanner {...{bannerData}}/>
      <ViewContain $alignItems={"center"} $paddingX={"10%"} $paddingY={"5%"}>
        <BridgeIntroText className="bridge_intro-text" dangerouslySetInnerHTML={{ __html: texts.bridge_intro_text }} />
        <ViewContain $paddingY={"5%"}>
          <FadeInSection>
            <InfoTitle dangerouslySetInnerHTML={{ __html: texts.bridge_map_title }}/>
          </FadeInSection>
          <FadeInSection>
            <SectionBridgeMap text={texts.bridge_map_description} actionButton={contactButton} buttonText={texts.bridge_text_button}/>
          </FadeInSection>
          <FadeInSection>
            <InfoTitle>
              {texts.bridge_info_title} Bridge<span style={{color: "#7DA03C", fontWeight: "600"}}>2</span>Zero?
            </InfoTitle>
          </FadeInSection>
        </ViewContain>
        <BridgeInfo text1={texts.bridge_info_text_1} text2={texts.bridge_info_text_2} icon1={imginfo1} icon2={imginfo2} />
        <BridgeInfo text1={texts.bridge_info_text_3} text2={texts.bridge_info_text_4} icon1={imginfo3} icon2={imginfo4} />
        <BridgeInfo text1={texts.bridge_info_text_5} icon1={imginfo5} />
        <ViewContain $paddingY={"5%"}>
          <FadeInSection>
            <BridgeRegulationCont>
              <BridgeRegulationText dangerouslySetInnerHTML={{ __html: texts.bridge_regulation_text }} />
              <PurpleButton onClick={contactButton} $textTransform={"uppercase"} $width={"54.8rem"} $height={"5rem"}>{texts.bridge_text_button}</PurpleButton>
            </BridgeRegulationCont>
          </FadeInSection>
        </ViewContain>
      </ViewContain>
    </>
  );
};

const BridgeIntroText = styled.div`
  margin: 3% 0 0 0;
  font-size: 3.6rem;
  font-weight: 600;
  color: var(--darkPurple);
  text-align: justify;

  span{
    color: var(--pink2);
  }
`

const BridgeRegulationCont = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 5rem;

  @media only screen and (orientation: portrait) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: justify;
  }
`
const BridgeRegulationText = styled.div`
  flex: 1;
  font-size: 3rem;
  font-weight: 300;
  color: var(--darkPurple);
`

export default BridgeIndex;
