import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { LangContext, translations } from "../../../../lib/Language";
import Layout from "../../_components/Layout";
import ViewField, { Images } from "../../_components/ViewField";
import useOpController from "../../_hooks/useOpController";
import CardsContain from "../../../_components/CardsContain";
import OpportunityCard from "../../_components/OpportunityCard";
import api from "../../../../lib/api";
import useFormUpdate from "../../_hooks/useFormUpdate";
import Modal from "../../_components/Modal";
import ScoreModal from "../../_components/ScoreModal";
import ActionPanel from "../../_components/ActionPanel";
import InfoOrganizationContact from "../../_components/InfoOrganizationContact";
import ViewContain from "../../../_components/ViewContain";
import { Label } from "../../_components/FormField";
import { GreyButton } from "../../../_components/GreyButton";
import Login from "../../login/Login";

const stagingEnvironment = process.env.REACT_APP_NODE_ENV === 'staging'

const OpportunityView = ({ object, currentUser, currentUserOrg, selectors, getSelector, hiddenPanelButtons, dispatch, adminUser, removeBackEvent, loginTexts }) => {
  const [data, saveData, setData] = useFormUpdate("opportunity", object);
  const [score, setScore] = useState(false);

  const l = useContext(LangContext);
  const f = translations[l].forms.opportunities;
  const c = translations[l].forms.controls;
  const d = translations[l].definition;
  const g = translations[l].general;
  const common = { data, f, c, d, l, view: true, selectors, getSelector };
  const accordion = useOpController(common);

  const currentUserId = currentUser && currentUser.id;
  const currrentOrgUserId = data.user_id;

  let props = {
    data: [
      {title: f.oppTypeFormTitle, component: <Definition {...{ d, l, c, g, data, currentUser, currentUserOrg, getSelector, currentUserId, currrentOrgUserId}}/>},
      {title: f.oppBasicFormTitle, component: <Contribution {...{ d, l, f, data, getSelector }} />},
      ...accordion,
			{title: f.oppAttachmentsFormTitle, component: <Attachments {...common}/>},
    ],
    c,
  };

  useEffect(() => {
    const showCardTimer = setTimeout(() => {
      const content = document.getElementById(`oppView`)
      content.scrollIntoView({ behavior: "smooth" })
      clearTimeout(showCardTimer)
    }, 300)
  }, [])

  return (
    <>
      <ViewContain id="oppView" $paddingY={"5%"} $paddingX={"10%"}>
        <InfoOrganizationContact {...{ currentUserId, currrentOrgUserId, currentUserOrg }} org={data.organization} opId={data.id} view />
        {currentUserId !== currrentOrgUserId && <BtnProposal {...{ data, g, currentUser, loginTexts}} />}
        <ViewContain $paddingY={"5%"}>
          <Layout {...props} view />
        </ViewContain>
        {currentUserId !== currrentOrgUserId && <BtnProposal {...{ data, g, currentUser, loginTexts}} />}
        {!hiddenPanelButtons && <ActionPanel {...{ data, saveData, setScore, dispatch, setData, removeBackEvent }} mode="preview" model="opportunities" />}
        <div>
        {(currentUser?.id === data.user_id || adminUser )&& data.proposals.length > 0 && <Proposals {...{ data, l }} />}
        </div>
      </ViewContain>
      {score && (
        <Modal width={"916px"} height={"650px"} title={"Tu oportunidad ha sido publicada"} close={() => { window.location = `${stagingEnvironment?"/marketplace":""}/opportunities/${data.id}`; }} >
          <ScoreModal score={score} listener={() => { window.location = `${stagingEnvironment?"/marketplace":""}/opportunities/${data.id}`; }} />
        </Modal>
      )}
    </>
  );
};

const BtnProposal = ({ data, g, currentUser, loginTexts }) => {
  const [showLogin, setShowLogin] = useState(false)
  console.log("CurrentUser",currentUser)

  const authenticity_token = document.querySelector('meta[name="csrf-token"]').content

  let handleClick = () => {
    if (currentUser) window.location = `${stagingEnvironment?"/marketplace":""}/proposals/new?opportunity_id=${data.id}`
    else setShowLogin(true) 
  };
  return (
    <>
      <ViewContain $alignItems={"center"}>
        <GreyButton $paddingY={"1rem"} $paddingX={"1rem"} onClick={handleClick} $textTransform={"uppercase"}>{g.postProposal}</GreyButton>
      </ViewContain>
      {showLogin && 
        <Modal width={"916px"} >
          <Login {...{authenticity_token}} texts={loginTexts} fromModal/>
        </Modal>
      }
    </>
  );
};

const BtnCloseOpportunity = ({ data, g }) => {
  let handleClick = () => {
    api.closeOpportunity({ id: data.id, authenticity_token: window.at }, (response) => {
      response.success ? location.reload() : alert(response.message);
    });
  };
  return (
    <ViewContain $alignItems={"center"}>
      <GreyButton $width={"24.9rem"} $height={"4.3rem"} onClick={handleClick} $textTransform={"uppercase"}>{g.closeOpportunity}</GreyButton>
    </ViewContain>
  );
};

const Definition = ({ d, l, c, g, data, currentUser, currentUserOrg, getSelector, currentUserId, currrentOrgUserId }) => {
  const ValidateDate = (date = "2023-03-17") => {
    // validate date for show button or field
    let today = new Date().getTime();
    let parseDate = Date.parse(date);
    let validation = today < parseDate;
    return validation;
  };

  return (
    <>
      {ValidateDate(data?.deadline) && currentUser && currentUserOrg && currentUserId === currrentOrgUserId && data?.published && <BtnCloseOpportunity {...{ data, g }} />}
      {data.idoneo && (
        <IdoneoCont>
          <img src="/icons/idoneidad.png" alt="idoneo" />
          <IdoneoDescription>{d.idoneo}</IdoneoDescription>
        </IdoneoCont>
      )}
      {data.partner_id && <ViewField object={data} f={d} t="partner" field="partner" />}
      <ViewField object={data} t="booleanSub" field="type" subField="offers" label={d.type} />
      <ViewField object={data} t="subContent" field="type" subField="name" label={d.definition} />
      <ViewField object={data} t="sub_sectors" field="sub_sectors" label={c.selectSectors} />
      {!ValidateDate(data?.deadline) && <ViewField object={data} t="text" field="deadline" label={d.dateClose} />}
      <ViewField object={data} t="selector" field="nb_affected" label={d.impact} getSelector={getSelector} />
      {data.tags.length !== 0 && <ViewField object={data} t="contentArr" field="tags" label={d.concept} />}
      <OdsForm>
        <Label small={true} help={true} label={g.sdg}>
          {translations[l].odsExplication.explication}
        </Label>
        <OdsContainer>
          {data.ods.map((e) => ( <img key={"ODS_" + e.id} src={e.image} alt={e.number} />
          ))}
        </OdsContainer>
      </OdsForm>
      {data.proposals.length > 0 && currentUserId === currrentOrgUserId && <ViewField object={{proposals_count: data.proposals.length}} t="text" field="proposals_count" label={g.submittedproposals}/>}
    </>
  );
};

const Contribution = ({ d, l, f, data, getSelector }) => {
  const i = translations[l].opportunity.impact;
  const props = { l, d, data };

  return (
    <>
      <ViewField {...props} t="text" field="title" label={f.viewTitle}/>
      <ViewField {...props} t="selector" field="participation" label={i.participation} getSelector={getSelector} />
      <ViewField object={data} f={d} t="text" field="description" />
      <ViewField object={data} f={d} t="text" field="motivation" />
      <ViewField object={data} f={d} t="country" field="country" />
      <ViewField {...props} t="text" field="other_participation" label={i.other_participation} getSelector={getSelector} />
    </>
  );
};

const Attachments = (props) => (
  <>
    <Images object={props.data} t="images" />
    {props.data.files.length > 0 &&<ViewContain $paddingY={"4%"} >
      <ViewField object={props.data} t="files" field="files" label={props.f.anexosView} />
    </ViewContain>}
    {props.data.urlVideos.length > 0 && <ViewField object={props.data} t="videos" field="urlVideos" label={props.c.urlVideosView} />}
    {props.data.attached_links.length > 0 && <ViewContain $paddingY={"4%"} >
      <ViewField object={props.data} t="links" field="attached_links" label={props.f.attachedLinksView} />
    </ViewContain>}
  </>
);

const Proposals = ({ data, l }) => (
  <>
    <h3>{translations[l].general.proposals}</h3>
    <CardsContain>
      {data.proposals.map((proposal) => (
        <OpportunityCard opportunity={proposal} key={proposal.id + "op"} proposal/>
      ))}
    </CardsContain>
  </>
);

//Idoneo
const IdoneoCont = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`
const IdoneoDescription = styled.span`
  font-size: 2.5rem;
  font-weight: 400;
  color: var(--grey5);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

//ODS
const OdsForm = styled.div`
  display: flex;
  flex-direction: column;
`
const OdsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  img{
    width: 80px;
  }
`

export default OpportunityView;