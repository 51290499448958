import styled from "styled-components";

const InfoText = styled.p`
  font-family: ${({$fontFamily}) => $fontFamily || "var(--Roboto)"};
  font-size: ${({$fontSize}) => $fontSize || "3rem"};
  font-weight: ${({$fontWeight}) => $fontWeight || "400"};
  color: ${({$color}) => $color || "#474747"};
  margin: ${({$margin}) => $margin || "10rem 0"};
  text-align: ${({$textAlign}) => $textAlign || ""};
  strong{
    font-weight: 600;
  }
`

export default InfoText