import React from "react";
import { styled } from 'styled-components';
import FadeInSection from "../../_components/FadeInSection";
import { LazyLoadImage } from "react-lazy-load-image-component";

const BridgeInfo = ({ icon1, text1, icon2, text2 }) => {
  const dualInfo = text2 && icon2;
  return (
    <BridgeInfoMain $dualInfo={dualInfo}>
      <FadeInSection>
        <BridgeInfoCont>
          <BridgeInfoIcon>
            <LazyLoadImage src={icon1} alt="icon1" width="100%" height="100%" style={{ objectFit: "cover"}}/>
          </BridgeInfoIcon>
          <BridgeInfoText dangerouslySetInnerHTML={{ __html: text1 }} />
        </BridgeInfoCont>
      </FadeInSection>
      {dualInfo && (
        <FadeInSection>
          <BridgeInfoCont>
            <BridgeInfoIcon>
              <LazyLoadImage src={icon2} alt="icon2" width="100%" height="100%" style={{ objectFit: "cover"}}/>
            </BridgeInfoIcon>
            <BridgeInfoText dangerouslySetInnerHTML={{ __html: text2 }} />
          </BridgeInfoCont>
        </FadeInSection>
      )}
    </BridgeInfoMain>
  );
};

const BridgeInfoMain = styled.div`
  display: grid;
  grid-template-columns: ${({$dualInfo}) => $dualInfo ? "repeat(2, 1fr)" : "auto"};
  margin: 1rem;

  @media only screen and (orientation: portrait) {
    grid-template-columns: auto;
    gap: 2rem;
  }
`
const BridgeInfoCont = styled.div`
  display: flex;
  align-items: center;
  gap: 5rem;
  padding: 3% 4%;
  background-color: #d9d9d966;
  @media only screen and (orientation: portrait) {
    padding: 2% 3%;
  }
`
const BridgeInfoIcon = styled.div`
  width: 23.3rem;
  height: 23.3rem;
  background-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  overflow: hidden;

  @media only screen and (orientation: portrait) {
    width: 16.3rem;
    height: 16.3rem;
  }
`
const BridgeInfoText = styled.div`
  color: var(--darkPurple);
  font-size: 3rem;
  font-weight: 300;
  flex: 1;
`

export default BridgeInfo;
