import styled from "styled-components";

const CardContain = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 3rem;

  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
  }
`

export default CardContain