import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styled from 'styled-components';

const RoundFlag = ({ id, width, height }) => {
  const [flagSrc, setFlagSrc] = useState(null);

  useEffect(() => {
    if (id) {
      import(`../../images/flags/${id.toLowerCase()}.svg`)
        .then((module) => setFlagSrc(module.default))
    }
  }, [id]);

  return (
    <RoundFlagCont id={id} style={{ width, height }}>
      {flagSrc && <LazyLoadImage alt={id} src={flagSrc} width="100%" height="100%" />}
    </RoundFlagCont>
  );
};

const RoundFlagCont = styled.div`
	display: inline-block;
	background-color: var(--grey1);
	border-radius: 100% !important;
	-moz-border-radius: 100% !important;
	-o-border-radius: 100% !important;
	-ms-border-radius: 100% !important;
  overflow:hidden;
	width:4rem;
	height:4rem;
	img{
		width: 100%;
		height: 100%;
	}
`

export default RoundFlag