import styled from "styled-components"

const FiltersContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4,1fr);
  width: 100%;
  column-gap: 2.5rem;
  margin: 2% auto 0;
  padding: 0 5%;

  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: auto;
    column-gap: unset;
    row-gap: 1rem;
  }
`

export default FiltersContainer