import styled from "styled-components";

const InfoContain = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5%;
  margin: ${({$margin})=>$margin || "5%"};

  h2{
    font-family: var(--BarlowCondensed);
    font-size: 5rem;
    font-weight: 600;
    color: var(--grey2);
    text-align: center;
  }
  span{
    font-family: var(--Roboto);
    font-size: 3rem;
    font-weight: 400;
    color: var(--grey2);
    margin: 0;
  }
`

export default InfoContain