import React, { useReducer, useState, useEffect } from 'react'
import api from '../../../lib/api'
import LoadingBars from '../../_components/LoadingBars'
import ViewContain from '../../_components/ViewContain'
import { translations } from '../../../lib/Language'

const reducer = (state, action) => {
  if (action.type === "init") return { ...state, ...action, loading: false }
  if (action.type === "view") return { ...state, view: "view", object: action.object }
  if (action.type === "form") return { ...state, view: "form", selectedForm: action.selectedForm }
  if (action.type === "loading") return { ...state, loading: <ViewContain $paddingY={"3%"} $alignItems={"center"}><LoadingBars color={"#5E5E5E"}/></ViewContain> }
  if (action.type === "save") return { ...state, loading: false, object: action.object, view: action.view, errors: action.errors, selectedForm: state.selectedForm + 1 }
  if (action.type === "change") return { ...state, selectedForm: action.selectedForm }
}

const useAppController = (lang, obj, controller, initView, set = false) => {
  const [state, dispatch] = useReducer(reducer, { controller: controller, loading: <ViewContain $paddingY={"3%"} $alignItems={"center"}><LoadingBars color={"#5E5E5E"}/> </ViewContain>})
  const [images, setImages] = useState({})

  const messages = translations[lang].forms.messages

  const addImage = (e) => {
    const im = { ...images }
    im[e.target.name] = e.target.files[0]
    setImages(im)
  }

  const saveObject = async (o, dontPrint = false, fistLoading) => {
    api.createOrUpdate(o, images, controller, (response) => {
      if (response.success === true) {
        if (!fistLoading) alert(messages.savedSuccesfully)
        if (dontPrint) return dontPrint({})
        dispatch({ type: "save", object: response.object, view: "form", errors: {} })
        // window.flash({ messages: "OK", type: "success" })
        if(set) set(response.object)
      } else {
        const errors = JSON.parse(response.errors)
        if (!fistLoading) alert(`${messages.error} \n\n ${Object.entries(JSON.parse(response.errors)).map(e => e.join(" "))}`)
        if (dontPrint) return dontPrint(errors)
        dispatch({ type: "save", object: o, view: "form", errors })
        // window.flash({ messages: Object.entries(JSON.parse(response.errors)).map(e => e.join(" ")) })
      }
    })
  }

  const getSelector = (field, selectorField, tag) => {
    const selected = state.selectors[(selectorField || field)].find(e => e.tag === (tag || state.object[field]))
    return selected ? selected.name : "N/A"
  }

  useEffect(() => {
    const getSelectors = new Promise(resolve => api.getSelectors(lang, (r) => resolve(r)))
    const getObj = new Promise(resolve => obj ? resolve(obj) : api.new(controller, (r) => resolve(r)))
    const getErrors = new Promise(resolve => obj ?
      obj.errors ? resolve(obj.errors) : saveObject(obj || { id: null }, (r) => { resolve(r) }, true)
      : resolve({})
    )
    Promise.all([getSelectors, getObj, getErrors]).then(m => {
      dispatch({ type: "init", selectors: m[0], object: m[1], errors: m[2], view: initView || (m[1].id ? "view" : "form") })
    })
  }, [])

  return { ...state, dispatch, saveObject, getSelector, addImage }
}

export default useAppController