import React, { useContext, useState } from "react";
import { LangContext, translations } from "../../../lib/Language";
import FormField, { FormSection } from "../_components/FormField";
import ViewField, { ViewSection } from "../_components/ViewField";
import api from "../../../lib/api";
import LoadingBars from "../../_components/LoadingBars";
import ViewContain from "../../_components/ViewContain";

const callDetail = (common) => {
  const l = useContext(LangContext);
  const d = translations[l].opportunity[`detail${common.data.type.number}`];
  const h = translations[l].help[`detail${common.data.type.number}`];
  let props = { ...common, h, f: d };

  const details = [
    <Details1 {...props} />,
    <Details2 {...props} />,
    <Details3 {...props} />,
    <Details4 {...props} />,
  ];

  return details[common.data.type.number - 1];
};

const useOpController = (common) => {
  const l = useContext(LangContext);

  if (!common.selectors) return [{ label: "...", component: <div /> }];
  const a = translations[l].opportunity.accordion;
  const org = translations[l].opportunity.organization;
  const has = translations[l].opportunity.hasCommunity;
  const profile = translations[l].opportunity.customerProfile;
  const needs = translations[l].opportunity.needsCommunity;
  const ter = translations[l].territory;
  const d = translations[l].definition;

  let acc = [
    { title: a.details, component: callDetail(common) },
    { title: a.impact, component: <Impact {...common} /> },
  ];

  if (common.data.type.has_community) {
    if (!common.view)
      acc.push({
        title: a.organization,
        component: (
          <OrganizationType {...common} d={d} trOrg={org} trHas={has} />
        ),
      });
    if (!common.view)
      acc.push({
        title: a.territory,
        component: (
          <Territory
            {...common}
            f={ter}
            data={common.data.territory}
            errors={common.data.territory.errors}
            saveData={(e) => common.saveData(e, "territory")}
          />
        ),
      });
  } else {
    if (common.data.type.number === 3){
      const dataFields = common.data.community
      if (!common.view || (common.view && (dataFields.description || dataFields.location || dataFields.population || dataFields.infrastructure_type.length > 0 || dataFields.other_infrastructure_type || dataFields.unfavorable || dataFields.unfavorable_which || dataFields.conditions)))
        acc.push({
          title: a.customerProfile,
          component: (
            <CustomerProfile
              {...common}
              f={profile}
              data={common.data.community}
              errors={common.data.community.errors}
              saveData={(e) => common.saveData(e, "community")}
            />
          ),
        });
    }
    else
      acc.push({
        title: a.findCommunity,
        component: (
          <NeedsCommunity
            {...common}
            f={needs}
            data={common.data.community}
            errors={common.data.community.errors}
            saveData={(e) => common.saveData(e, "community")}
          />
        ),
      });
  }

  return acc;
};

export default useOpController

const Details1 = (props) => {
  return props.view ? (
    <>
      <ViewField {...props} t="text" field="causeView" />
      <ViewField
        {...props}
        t="bolean"
        field="solutions"
        label={props.f.solutionsView}
      />
      <ViewField
        {...props}
        t="text"
        field="solutions_which"
        label={props.f.solutions_whichView}
      />
      <ViewField
        {...props}
        t="select"
        field="detail_value"
        label={props.f.valueView}
        secondary="value"
      />
      <ViewField
        {...props}
        t="list"
        field="detail_value_multiple"
        label={props.f.valueView}
      />
      <ViewField
        {...props}
        t="text"
        field="other_detail_value"
        label={props.f.value_OTHER_TEXTView}
      />
    </>
  ) : (
    <>
      <FormField {...props} t="textarea" field="cause" />
      <FormField {...props} t="boolean" field="solutions" />
      {props.data.solutions === true && (
        <FormField {...props} t="textarea" field="solutions_which" fieldRequired/>
      )}
      <FormField
        {...props}
        t="checkboxes"
        field="detail_value_multiple"
        label={props.f.value}
      />
      {props.data.detail_value_multiple.includes("OTHS") && (
        <FormField
          {...props}
          t="textarea"
          field="other_detail_value"
          label={props.f.value_OTHER_TEXT}
        />
      )}
    </>
  );
};

const Details2 = (props) => {
  return props.view ? (
    <>
      <ViewSection
        label={{ label: props.f.capacityView }}
        fields={[
          {
            object: props.data,
            t: "text",
            field: "capacity_quantity",
            label: props.f.capacity_quantityView,
          },
          {
            object: props.data,
            t: "text",
            field: "capacity_unit",
            label: props.f.capacity_unitView,
          },
          {
            object: props.data,
            t: "text",
            field: "capacity_frequency",
            label: props.f.capacity_frequencyView,
          },
        ]}
      />
      <ViewField
        {...props}
        t="checkboxes"
        field="markets"
        label={props.f.marketsView}
      />
      <ViewField
        {...props}
        t="select"
        field="development_level"
        label={props.f.development_levelView}
      />
      <ViewField
        {...props}
        t="select"
        field="innovation"
        label={props.f.innovationView}
      />
    </>
  ) : (
    <>
      <FormSection
        label={{ label: props.f.capacity }}
        fields={[
          { ...props, t: "number", field: "capacity_quantity" },
          { ...props, t: "text", field: "capacity_unit" },
          { ...props, t: "text", field: "capacity_frequency" },
        ]}
      />
      <FormField {...props} t="checkboxes" field="markets" />
      <FormField {...props} t="select" field="development_level" />
      <FormField {...props} t="select" field="innovation" />
      {props.data.innovation === "OTRA" && (
        <FormField
          {...props}
          t="textarea"
          field="other_innovation"
          label={props.f.innovation_OTHER_TEXT}
        />
      )}
    </>
  );
};

const Details3 = (props) => {
  return props.view ? (
    <>
      <ViewField
        {...props}
        t="select"
        field="vulnerable"
        label={props.f.vulnerableView}
      />
      <ViewField
        {...props}
        t="select"
        field="age_range"
        label={props.f.age_rangeView}
      />
      <ViewField
        {...props}
        t="select"
        field="affordability"
        label={props.f.affordabilityView}
      />
      <ViewField
        {...props}
        t="text"
        field="other_affordability"
        label={props.f.affordability_OTHER_TEXTView}
      />
      <ViewField
        {...props}
        t="select"
        field="detail_development"
        label={props.f.developmentView}
        secondary="development"
      />
      <ViewField
        {...props}
        t="select"
        field="detail_value"
        label={props.f.valueView}
        secondary="value3"
      />
      <ViewField
        {...props}
        t="list"
        field="detail_value_multiple"
        label={props.f.valueView}
      />
      <ViewField
        {...props}
        t="text"
        field="other_detail_value"
        label={props.f.value_OTHER_TEXTView}
      />
      <ViewField
        {...props}
        t="select"
        field="action"
        label={props.f.actionView}
      />
      <ViewField
        {...props}
        t="select"
        field="activity"
        label={props.f.activityView}
      />
      <ViewField
        {...props}
        t="text"
        field="other_activity"
        label={props.f.activity_OTHER_TEXTView}
      />
    </>
  ) : (
    <>
      <FormField {...props} t="select" field="vulnerable" />
      <FormField {...props} t="select" field="age_range" />
      <FormField {...props} t="select" field="affordability" />
      {props.data.affordability === "OTET" && (
        <FormField
          {...props}
          t="textarea"
          field="other_affordability"
          label={props.f.affordability_OTHER_TEXT}
        />
      )}
      <FormField
        {...props}
        t="select"
        field="detail_development"
        label={props.f.development}
        selector="development"
      />
      <FormField
        {...props}
        t="checkboxes"
        field="detail_value_multiple"
        label={props.f.valueView}
      />
      {props.data.detail_value_multiple.includes("OTHS") && (
        <FormField
          {...props}
          t="textarea"
          field="other_detail_value"
          label={props.f.value_OTHER_TEXT}
        />
      )}
      <FormField {...props} t="select" field="action" />
      <FormField {...props} t="select" field="activity" />
      {props.data.activity === "OTHS" && (
        <FormField
          {...props}
          t="textarea"
          field="other_activity"
          label={props.f.activity_OTHER_TEXT}
        />
      )}
    </>
  );
};

const Details4 = (props) => {
  return props.view ? (
    <>
      <ViewSection
        label={{ label: props.f.buy_capacityView }}
        fields={[
          {
            ...props,
            t: "text",
            object: props.data,
            field: "buy_capacity_quantity",
          },
          {
            ...props,
            t: "text",
            object: props.data,
            field: "buy_capacity_unit",
          },
          {
            ...props,
            t: "text",
            object: props.data,
            field: "buy_capacity_frequency",
          },
        ]}
      />
      <ViewField {...props} t="select" field="innovation" />
      <ViewField
        {...props}
        t="select"
        field="detail_value"
        label={props.f.valueView}
        secondary="value"
      />
      <ViewField
        {...props}
        t="list"
        field="detail_value_multiple"
        label={props.f.valueView}
      />
      <ViewField
        {...props}
        t="text"
        field="other_detail_value"
        label={props.f.value_OTHER_TEXTView}
      />
      <ViewSection
        label={{ label: props.f.sails_capacityView }}
        fields={[
          {
            ...props,
            t: "text",
            object: props.data,
            field: "sales_capacity_quantity",
            label: props.f.sails_capacity_quantityView,
          },
          {
            ...props,
            t: "text",
            object: props.data,
            field: "sales_capacity_unit",
            label: props.f.sails_capacity_quantityView,
          },
          {
            ...props,
            t: "text",
            object: props.data,
            field: "sales_capacity_frequency",
            label: props.f.sails_capacity_quantityView,
          },
        ]}
      />
    </>
  ) : (
    <>
      <FormSection
        label={{ label: props.f.buy_capacity }}
        fields={[
          { ...props, t: "number", field: "buy_capacity_quantity" },
          { ...props, t: "text", field: "buy_capacity_unit" },
          { ...props, t: "text", field: "buy_capacity_frequency" },
        ]}
      />
      <FormField {...props} t="select" field="innovation" />
      <FormField
        {...props}
        t="checkboxes"
        field="detail_value_multiple"
        label={props.f.valueView}
      />
      {props.data.detail_value_multiple.includes("OTHS") && (
        <FormField
          {...props}
          t="textarea"
          field="other_detail_value"
          label={props.f.value_OTHER_TEXT}
        />
      )}
      <FormSection
        label={{ label: props.f.sails_capacity }}
        fields={[
          { ...props, t: "number", field: "sales_capacity_quantity" },
          { ...props, t: "text", field: "sales_capacity_unit" },
          { ...props, t: "text", field: "sales_capacity_frequency" },
        ]}
      />
    </>
  );
};

export const Impact = (props) => {
  const l = useContext(LangContext);
  const i = translations[l].opportunity.impact;
  const h = translations[l].help.impact;
  return props.view ? (
    <>
      <ViewField {...props} t="sector" field="sub_sector_id" label={i.sector} />
      <ViewField
        {...props}
        t="text"
        field="other_sector"
        label={i.sector_OTRO_TEXT}
      />
      <ViewField
        {...props}
        t="list"
        field="environmental"
        label={i.environmentalView}
      />
      <ViewField
        {...props}
        t="text"
        field="other_environmental"
        label={i.other_environmentalView}
      />
      <ViewField
        {...props}
        t="list"
        field="social"
        help={h.social}
        label={i.socialView}
      />
    </>
  ) : (
    <>
      <FormField
        {...props}
        t="sectors"
        field="sub_sector_id"
        label={i.sector}
      />
      <FormField
        {...props}
        t="text"
        field="other_sector"
        label={i.sector_OTRO_TEXT}
      />
      <FormField
        {...props}
        t="checkboxes"
        field="environmental"
        label={i.environmental}
      />
      {props.data.environmental.includes("OOTH") && (
        <FormField
          {...props}
          t="textarea"
          field="other_environmental"
          label={i.other_environmental}
        />
      )}
      <FormField
        {...props}
        t="checkboxes"
        field="social"
        label={i.social}
        help={h.social}
      />
    </>
  );
};

export const OrganizationType = (props) => {
  const [loading, setLoading] = useState(false);

  const organizationFields = {
    challenges: [],
    other_challenges: null,
    credibility_people: null,
    decision_tools: null,
    influence: null,
    leadership: null,
    market_orientation: null,
    nb_benefited: null,
    partipation_network: null,
    projects_budget: null,
  };

  const communityFields = {
    address: null,
    affected: null,
    antiguity: null,
    associate: null,
    experience_development: null,
    experience_management: null,
    credibility_ong: null,
    other_association: null,
    characteristic: null,
    city: null,
    country: null,
    financing: null,
    financing_type: [],
    other_financing: null,
    name: null,
    role: null,
    time: null,
  };

  const setFormValues = (data) => {
    const selectedForm =
      data.organization_type === "PROSE"
        ? [organizationFields, "agent_organization"]
        : data.organization_type === "MISMC"
        ? [communityFields, "community"]
        : null;
    if (selectedForm) {
      let tempData = { ...data };
      for (const key in selectedForm[0]) {
        if (Object.hasOwnProperty.call(selectedForm[0], key)) {
          const element = selectedForm[0][key];
          tempData[selectedForm[1]][key] = element;
        }
      }
      return tempData;
    }
  };

  const handleOrganizationType = (e) => {
    let updateData = { ...props.data, organization_type: e.target.value };
    updateData = setFormValues(updateData);
    setLoading(<ViewContain $alignItems={"center"}><LoadingBars color={"#FFF"}/> </ViewContain>);
    api.save(updateData, "opportunities", (response) => {
      props.setData(response.object);
      setLoading(false);
    });
  };

  return props.view ? (
    <>
      <ViewField
        {...props}
        t="select"
        f={props.trOrg}
        field="organization_type"
        selector="orgrol"
      />
      {props.data.organization_type === "MISMC" && <Organization {...props} />}
      {props.data.organization_type === "PROSE" && <HasCommunity {...props} />}
    </>
  ) : (
    <>
      {loading ? (
        loading
      ) : (
        <>
          <FormField
            {...props}
            t="select"
            f={props.trOrg}
            field="organization_type"
            selector="orgrol"
            saveData={handleOrganizationType}
          />
          {props.data.organization_type === "MISMC" && (
            <Organization
              {...props}
              data={props.data.agent_organization}
              errors={props.data.agent_organization.errors}
              saveData={(e) => props.saveData(e, "agent_organization")}
              f={props.trOrg}
            />
          )}
          {props.data.organization_type === "PROSE" && (
            <HasCommunity
              {...props}
              data={props.data.community}
              errors={props.data.community.errors}
              saveData={(e) => props.saveData(e, "community")}
              f={props.trHas}
            />
          )}
        </>
      )}
    </>
  );
};

export const Organization = (props) => {
  return (
    <>
      <FormField {...props} t="checkboxes" field="challenges" />
      {props.data.challenges.includes("ot") && (
        <FormField {...props} t="textarea" field="other_challenges" />
      )}
      <FormField {...props} t="select" field="projects_budget" />
      <FormField
        {...props}
        t="select"
        field="nb_benefited"
        selector="nb_affected"
      />
      <FormField
        {...props}
        t="select"
        field="partipation_network"
        selector="null_high"
      />
      <FormField {...props} t="select" field="influence" selector="null_high" />
      <FormField
        {...props}
        t="select"
        field="leadership"
        selector="null_high"
      />
      <FormField
        {...props}
        t="select"
        field="credibility_people"
        selector="null_high"
      />
      <FormField
        {...props}
        t="select"
        field="market_orientation"
        selector="null_high"
      />
      <FormField
        {...props}
        t="select"
        field="decision_tools"
        selector="null_high"
      />
    </>
  );
};

export const HasCommunity = (props) => {
  return props.view ? (
    <>
      <ViewField {...props} f={props.d} t="country" field="country" />
      <ViewField {...props} t="text" field="city" f={props.f} />
    </>
  ) : (
    <>
      <FormField {...props} t="textarea" field="name" />
      <FormField {...props} t="countries" field="country" />
      <FormField {...props} t="text" field="city" />
      <FormField {...props} t="text" field="address" />
      <FormField {...props} t="select" field="role" selector="rol" />
      <FormField
        {...props}
        t="select"
        field="affected"
        selector="nb_affected"
      />{" "}
      <FormField {...props} t="select" field="antiguity" />
      <FormField {...props} t="select" field="characteristic" />
      <FormField
        {...props}
        t="select"
        field="associate"
        label={props.f.association}
        selector="association"
      />
      {props.data.associate === "OTHS" && (
        <FormField
          {...props}
          t="text"
          field="other_association"
          label={props.f.association_OTRO_TEXT}
        />
      )}
      <FormField
        {...props}
        t="select"
        field="experience_management"
        selector="null_high"
      />
      <FormField
        {...props}
        t="select"
        field="experience_development"
        selector="null_high"
      />
      <FormField
        {...props}
        t="select"
        field="credibility_ong"
        selector="null_high"
      />
      <FormField
        {...props}
        t="checkboxes"
        field="financing_type"
        selector={"financing"}
      />
      {props.data.financing_type.includes("OTHS") && (
        <FormField {...props} t="textarea" field="other_financing" />
      )}
      <FormField {...props} t="select" field="time" />
    </>
  );
};

const CustomerProfile = (props) => {
  return props.view ? (
    <>
      <ViewField {...props} t="text" field="description" />
      <ViewField
        {...props}
        t="select"
        field="location"
        selector="region_location"
      />
      <ViewField
        {...props}
        t="select"
        field="population"
        selector="population_need"
      />
      <ViewField
        {...props}
        t="list"
        field="infrastructure_type"
        selector="infrastructure_type"
      />
      <ViewField
        {...props}
        t="text"
        field="other_infrastructure_type"
        selector="other_infrastructure_type"
      />
      <ViewField {...props} t="select" field="unfavorable" />
      <ViewField {...props} t="text" field="unfavorable_which" />
      <ViewField
        {...props}
        t="text"
        field="conditions"
        label={props.f.other_conditions}
        selector="other_conditions"
      />
    </>
  ) : (
    <>
      <FormField {...props} t="textarea" field="description" />
      <FormField
        {...props}
        t="select"
        field="location"
        selector="region_location"
      />
      <FormField {...props} t="checkboxes" field="infrastructure_type" />
      {props.data.infrastructure_type.includes("OTHS") && (
        <FormField {...props} t="text" field="other_infrastructure_type" />
      )}
      <FormField
        {...props}
        t="select"
        field="population"
        selector="population_need"
      />
      <FormField {...props} t="select" field="unfavorable" />
      {props.data.unfavorable === "OTHS" && (
        <FormField {...props} t="text" field="unfavorable_which" />
      )}
      <FormField
        {...props}
        t="textarea"
        field="conditions"
        label={props.f.other_conditions}
        selector="other_conditions"
      />
    </>
  );
};

const NeedsCommunity = (props) =>
  props.view ? (
    <>
      <ViewField {...props} t="text" field="description" />
      <ViewField {...props} t="text" field="location" />
      <ViewField
        {...props}
        t="select"
        field="associate"
        label={props.f.associationView}
        selector="association_need"
      />
      <ViewField
        {...props}
        t="text"
        field="other_association"
        label={props.f.association_OTRO_TEXT}
      />
      <ViewField
        {...props}
        t="select"
        field="population"
        selector="population_need"
      />
      <ViewField {...props} t="select" field="unfavorable" />
      <ViewField {...props} t="text" field="unfavorable_which" />
      <ViewField
        {...props}
        t="text"
        field="conditions"
        label={props.f.other_conditions}
        selector="other_conditions"
      />
    </>
  ) : (
    <>
      <FormField {...props} t="textarea" field="description" />
      <FormField {...props} t="text" field="location" />
      <FormField
        {...props}
        t="select"
        field="associate"
        label={props.f.association}
        selector="association_need"
      />
      {props.data.associate === "OTHS" && (
        <FormField
          {...props}
          t="text"
          field="other_association"
          label={props.f.association_OTRO_TEXT}
        />
      )}
      <FormField
        {...props}
        t="select"
        field="population"
        selector="population_need"
      />
      <FormField {...props} t="select" field="unfavorable" />
      {props.data.unfavorable === "OTHS" && (
        <FormField {...props} t="text" field="unfavorable_which" />
      )}
      <FormField
        {...props}
        t="textarea"
        field="conditions"
        label={props.f.other_conditions}
        selector="other_conditions"
      />
    </>
  );

export const Territory = (props) => {
  const agrees = [
    "work",
    "trust",
    "presence",
    "public",
    "corporative",
    "communities",
    "order",
    "alliances",
    "convoke",
    "collaboration",
    "sinergy",
  ];
  return (
    <>
      <FormField
        {...props}
        t="select"
        field="territory_population"
        selector="population"
      />
      <FormField {...props} t="checkboxes" field="territory_access" />
      {agrees.map((e) => (
        <FormField
          {...props}
          t="select"
          key={"agree" + e}
          field={e}
          selector="agree"
        />
      ))}
      <FormField {...props} t="checkboxes" field="condition" />
      {props.data.condition.includes("ot") && (
        <FormField
          {...props}
          t="text"
          field="other_condition"
          label={props.f.condition_OTRO_TEXT}
        />
      )}
    </>
  );
};
