import React, { useState, useContext, useEffect } from "react";
import { LangContext, translations } from "../../../../lib/Language";
import useFormUpdate from "../../_hooks/useFormUpdate";
import usePrController from "../../_hooks/usePrController";
import FormField, { Images, Label } from "../../_components/FormField";
import ActionPanel from "../../_components/ActionPanel";
import Layout from "../../_components/Layout";
import Modal from "../../_components/Modal";
import ScoreModal from "../../_components/ScoreModal";
import api from "../../../../lib/api";
import ViewContain from "../../../_components/ViewContain";
import InfoOrganizationContact from "../../_components/InfoOrganizationContact";
import FormProgress from "../../_components/FormProgress";

const debug = false;
const stagingEnvironment = process.env.REACT_APP_NODE_ENV === 'staging'

const ProposalForm = ({ object, selectors, errors, dispatch, removeBackEvent, selectedForm }) => {
  const l = useContext(LangContext);
  const f = translations[l].forms.opportunities;
  const c = translations[l].forms.controls;
  const [data, saveData, setData] = useFormUpdate("proposal", object);
  const [score, setScore] = useState(false);

  const common = { data, f, c, selectors, l, saveData, setData, errors};
  const accordion = usePrController(common);

  const saveForm = () => {
    api.save(data, "proposals", (res) => {
      dispatch({
        type: "save",
        object: res.object,
        view: "form",
        errors: res.object.errors,
      });
      alert(res.message);
    });
  }

  const changeForm = (i) => { dispatch({type:"change", selectedForm: i})}

  const props = {
    data:[
      {title: f.oppBasicFormTitle, component: <Definition {...common}/>},
      ...accordion,
			{title: f.oppAttachmentsFormTitle, component: <Attachments {...common} />},
    ],
    c,
    saveForm,
    changeForm,
    selectedForm,
  };

  return (
    <>
      <ViewContain id="proposalForm" $paddingY={"4%"} $paddingX={"10%"}>
        <InfoOrganizationContact org={data.organization} />
        <ViewContain $paddingY={"2%"}/>
        <FormProgress {...{data}}/>
        <ViewContain $paddingY={"5%"}>
          <Layout {...props} form/>
        </ViewContain>
        <ActionPanel {...{ data, saveData, setScore, dispatch, removeBackEvent }} mode="edit" model="proposals" />
      </ViewContain>
      {score && (
        <Modal>
          <ScoreModal
            score={score}
            listener={() =>
              api.publish(data.id, "proposals", (res) => {
                if (res.success && res.success === true) {
                  setData(res.object);
                  window.location = `${stagingEnvironment?"/marketplace":""}/opportunities/` + data.id;
                }
              })
            }
          />
        </Modal>
      )}
      {debug && <pre>{JSON.stringify(data, undefined, 4)}</pre>}
    </>
  );
};

export default ProposalForm;

const Definition = (props) => {
  const d = translations[props.l].definition;

  return(
    <>
      <FormField t="text" {...props} field="title" label={props.f.addTitle} />
      <FormField {...props} f={d} t="textarea" field="description" />
      <FormField {...props} f={d} t="textarea" field="conditions" />
      <FormField {...props} f={d} t="textarea" field="motivation" />
    </>
  )
};

const Attachments = (props) => {
  const d = translations[props.l].definition;
  return(
    <>
      <Images {...props} f={d} field="images" selector="Proposal" />
      <ViewContain $paddingY={"4%"}>
        <FormField {...props} t="files" field="files" label={props.f.anexos} selector="Proposal" accept={"application/pdf"} />
      </ViewContain>
      <Label small={true} label="Videos" labelCenter />
      <FormField {...props} t="videos" field="urlVideos" f={props.c} />
      <ViewContain $paddingY={"4%"}>
        <FormField {...props} t="links" field="attached_links" label={props.f.attachedLinks} />
      </ViewContain>
    </>
  )
};