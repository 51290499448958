import styled from "styled-components";

const InfoTitle = styled.h2`
  font-family: var(--BarlowCondensed);
  font-size: 5rem;
  font-weight: 600;
  color: #361A3C;
  text-transform: ${({$textTransform}) => $textTransform || ""};
  text-align: center;

  span{
    font-weight: 400;
  }
`

export default InfoTitle