import React from 'react'
import styled from "styled-components";
import { WhiteButton } from '../../_components/WhiteButton';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const BridgeBanner = ({ bannerData = [],   }) => {
  return (
    <Banner>
      <BannerCont>
        <BannerInfoCont>
          <BannerItem>
            <BannerImage>
              <LazyLoadImage src={bannerData?.banner_image} alt={`banner image`} effect="blur" width="100%" height="100%" style={{position: 'absolute', top: "0", left: "0", objectFit: "cover"}}/>
            </BannerImage>
            <BannerInfo>
              <BannerInfoTitle dangerouslySetInnerHTML={{__html: bannerData.banner_title}}/>
              <BannerInfoText dangerouslySetInnerHTML={{__html: bannerData.banner_text1}}/>
              <BannerInfoText2 dangerouslySetInnerHTML={{__html: bannerData.banner_text2}}/>
							<WhiteButton $textTransform={"uppercase"} onClick={bannerData.button_action} $width={"28.2rem"} $fontSize={"2rem"} $paddingY={"1rem"}>{bannerData?.text_button}</WhiteButton>
            </BannerInfo>
          </BannerItem>
        </BannerInfoCont>
      </BannerCont>
    </Banner>
  )
}

const Banner = styled.div`
  height: 592px;
  position: relative;
  user-select: none;

  @media (max-width: 1368px) {
    height: 59.2rem;
  }
`

const BannerCont = styled.div`
  position: absolute;
  top: 0; 
  left: 50%;
  width: 100vw;
  height: 100%;
  overflow: hidden;
  transform: translate(-50%, 0);
`

const BannerInfoCont = styled.div`
  position: relative;
  display: flex;
  transition: transform 0.3s ease;
  height: 100%;
	/* max-width: 1512px; */
	margin: auto;
`

const BannerItem = styled.div`
  width: 100%;
  height: 100%;
	padding-top: 9rem;
  flex-shrink: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center; 
`

const BannerImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000080;
`

const BannerInfo = styled.div`
  max-width: 68rem;
  margin: 0 10%;
  gap: 3rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
	text-align: center;
`

const BannerInfoTitle = styled.h2`
	font-family: var(--BarlowCondensed);
  font-size: 6rem;
  font-weight: 500;
  color: #0E2714;
	margin: 0;
	
	span{
		color: #7DA03C
	}
`

const BannerInfoText = styled.h4`
	font-size: 2.4rem;
  font-weight: 300;
  margin: 0;
`

const BannerInfoText2 = styled.h3`
	font-size: 2.2rem;
  font-weight: 500;
  color: #253A2A;
  margin: 0;
`

export default BridgeBanner